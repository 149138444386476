import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MediaQueryService} from '../../services/media-query/media-query.service';
import {AlertController, ModalController} from '@ionic/angular';
import {MailsService} from '../../services/mails/mails.service';
import {Newsletter} from '../../interfaces/newsletter/newsletter';
import {MatSelectChange} from '@angular/material/select';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {NewsletterTemplateComponent} from '../modals/newsletter-template/newsletter-template.component';
import {NewsletterPreviewModalComponent} from '../modals/newsletter-preview-modal/newsletter-preview-modal.component';
import {Utils} from '../../classes/Utils';
import {UserService} from '../../services/user/user.service';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {SnackbarService} from "../../services/snackbar/snackbar.service";

declare function loadStripo(template, externalLib, emailId, userId): any;
declare function compileMail(func): any;
declare function getHtmlCss(func): any;

@Component({
  selector: 'app-newsletter-editor',
  templateUrl: './newsletter-editor.component.html',
  styleUrls: ['./newsletter-editor.component.scss'],
})
export class NewsletterEditorComponent implements OnInit {

  public newsletters: Array<Newsletter> = new Array<Newsletter>();
  public selectedNl: Newsletter;
  @ViewChild('stripoSettingsContainer') stripoSettings: ElementRef;
  @ViewChild('stripoPreviewContainer') stripoContainer: ElementRef;
  private companyId: string;

  constructor(public mediaQueryService: MediaQueryService
      , private modalController: ModalController
      , private mailService: MailsService
      , private alertController: AlertController
      , private snackBarService: SnackbarService
      , private userService: UserService) { }

  ngOnInit() {
    this.userService.getSessionDatas().pipe()
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.companyId = sessionData.customerDetails.companyId;
              }
            }
        );
    // this.mailService.getNewsletters().subscribe(value => {
    //   this.newsletters = value;
    //   if (!this.mailService.selectedTemplate && !this.selectedNl && this.newsletters.length > 0) {
    //     this.selectedNl = this.newsletters[0];
    //     this.mailService.getNewsletterAvailableArticles(this.selectedNl.id).subscribe(articles => {
    //       loadStripo({html: this.selectedNl.html, css: this.selectedNl.css}, this.getExternalLib(articles), this.selectedNl.id);
    //     });
    //   }
    // });
    //
    // if (this.mailService.selectedTemplate) {
    //   this.mailService.createNewsletterFromTemplateAndFolder(this.mailService.selectedTemplate, this.mailService.selectedFolder).subscribe(value => {
    //     this.selectedNl = value;
    //     this.mailService.getNewsletterAvailableArticles(value.id).subscribe(articles => {
    //       loadStripo({html: value.html, css: value.css}, this.getExternalLib(articles), value.id);
    //     });
    //   });
    // }
  }

  public ionViewWillEnter(): void {
    this.mailService.getNewsletters().subscribe(value => {
      this.newsletters = value;
      // if (!this.mailService.selectedTemplate && !this.selectedNl && this.newsletters.length > 0) {
      //   this.selectedNl = this.newsletters[0];
      //   this.mailService.getNewsletterAvailableArticles(this.selectedNl.id).subscribe(articles => {
      //     loadStripo({html: this.selectedNl.html, css: this.selectedNl.css}, this.getExternalLib(articles), this.selectedNl.id);
      //   });
      // }
    });

    if (this.mailService.selectedTemplate) {
      this.mailService.createNewsletterFromTemplateAndFolder(this.mailService.selectedTemplate, this.mailService.selectedFolder).subscribe(value => {
        this.mailService.selectedTemplate = undefined;
        this.selectedNl = value;
        this.newsletters.push(value);
        this.mailService.getNewsletterAvailableArticles(value.id).subscribe(articles => {
          loadStripo({html: value.html, css: value.css}, this.getExternalLib(articles), value.id, this.companyId);
        });
      });
    }
  }

  public articleMap = new Map();
  private getExternalLib(articles: Array<ArticleInterface>) {
    const self = this;
    this.articleMap.clear();
    articles.forEach(article => {
      this.articleMap.set('article_' + article.id + '', article.title + ' ' + article.corps);
    });
    const libArticles: string = this.mailService.getLibArticlesHtml(articles);
    return (function() {
      let externalLibrary;
      let dataSelectCallback;
      let cancelCallback;

      let close = function() {
        externalLibrary.style.visibility = 'hidden';
      };

      let cancelAndClose = function() {
        close();
        cancelCallback();
      };

      let initLibrary = function() {
        let div = document.createElement('div');
        div.innerHTML = '\
            <div id="externalSmartElementsLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
                <div style="margin: 10px;">\
                <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
                    <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                        <div>\
                           <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
                                <span>×</span>\
                            </button>\
                            <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">Articles disponibles</h4>\
                        </div>\
                    </div>\
                    <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                        <input id="inputArticles">\
                    </div>\
                    <div style="padding: 15px; height: 500px; overflow: auto;">' + libArticles + ' \
                    </div>\
                </div>\
            </div>';
        document.body.appendChild(div);
        var input = document.getElementById('inputArticles');
        function filterArticles(e) {
          //refresh display
          self.articleMap.forEach((value, key) => {
              document.getElementById(key).style.display = 'block';
          });
          const filter = e.target.value;
          self.articleMap.forEach((value, key) => {
            if (!value.includes(filter)) {
              document.getElementById(key).style.display = 'none';
            }
          });
        };
        input.addEventListener("input", filterArticles);
        externalLibrary = document.getElementById('externalSmartElementsLibrary');
        externalLibrary.querySelector('.close').addEventListener('click', cancelAndClose);
        document.querySelectorAll('.thumbnail')
            .forEach(thumbnail => thumbnail.addEventListener('click', function(e: any) {
              let exampleOfCallbackObject = {
                p_name: e.currentTarget.querySelector('p').innerText,
                p_price: e.currentTarget.querySelector('div').innerText,
                p_image: e.currentTarget.querySelector('a').innerText,
                p_old_price: e.currentTarget.querySelector('a').getAttribute('href'),
                p_description: e.currentTarget.querySelector('p').getAttribute('titlelink')
              };
              dataSelectCallback(exampleOfCallbackObject);
              close();
            }));
      };

      let renderLibrary = function() {
        if (!externalLibrary) {
          initLibrary();
        }
        externalLibrary.style.visibility = 'visible';
      };

      return {
        openLibrary: function(onDataSelectCallback, onCancelCallback, selectedModule) {
          // selectedModule - selected jQuery DOM element
          dataSelectCallback = onDataSelectCallback;
          cancelCallback = onCancelCallback;
          renderLibrary();
        }
      };
    })();
  }

  selectNewsletter($event: MatSelectChange) {
    this.selectedNl = $event.value;
    this.mailService.getNewsletterAvailableArticles(this.selectedNl.id).subscribe(articles => {
      loadStripo({html: this.selectedNl.html, css: this.selectedNl.css}, this.getExternalLib(articles), this.selectedNl.id, this.companyId);
    });
  }

  saveNewsletter() {
    const truc = this;
    getHtmlCss(function () {
      const htmlCss = (window as any).htmlcss;
      truc.selectedNl.html = htmlCss.html;
      truc.selectedNl.css = htmlCss.css;
      truc.mailService.updateNewsletter(truc.selectedNl).subscribe(value => {
        // truc.selectedNl = value;
      });
    });
  }


  sendNewsletter() {
    compileMail(function() {
      const htmlCss = (window as any).htmlcss;

    });
  }

  previewNewsletter() {
    // this.showPreview(this.selectedNl.html);
    const truc = this;
    compileMail(function() {
      truc.showPreviewBridge((window as any).htmlcss);
    });




    // compileMail(function() {
    //   const htmlCss = (window as any).htmlcss;
    //   showPreview(htmlCss);
    // });
  }
  showPreviewBridge(htmlCss: any) {
    this.showPreview(htmlCss);
  }

  async showPreview(htmlCss: any) {
    this.mailService.nl = this.selectedNl;
    this.mailService.nl.html = htmlCss.html;
    console.log(this.mailService.nl.html);
    const modal = await this.modalController.create({
      component: NewsletterPreviewModalComponent,
      cssClass: 'previewNlDialog',
      componentProps: {
      },
      backdropDismiss: true
    });

    return await modal.present();
  }

  async askHistorizeNewsletter() {
    const self = this;
    const alert = await this.alertController.create({
      header: 'Historisation de la newsletter',
      cssClass: 'desktopDialog',
      message: 'Votre newsletter va être historisée, elle ne sera plus accessible pour modification ou envoi. Êtes-vous sur de vouloir continuer ?',
      buttons: [
        {
          text: 'Oui',
          handler: () => {
            self.historizeNewsletter();
          }
        },
        {
          text: 'Non',
          handler: () => {
            self.modalController.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  historizeNewsletter() {
    this.mailService.historizeNewsletter(this.selectedNl.id).subscribe(value => {
      this.confirmNlHistorized();
      const index = this.newsletters.indexOf(this.selectedNl, 0);
      if (index > -1) {
        this.newsletters.splice(index, 1);
      }
      this.stripoSettings.nativeElement.innerHTML = '';
      this.stripoContainer.nativeElement.innerHTML = '<div style="margin-top: 100px;text-align: center; font-size: 22px;">Choisissez une newsletter ou rendez-vous dans favoris pour en créer une nouvelle.</div>';
    });
  }

  async confirmNlHistorized() {
    this.snackBarService.showSuccessSnackbar('Newsletter historisée', 'Votre newsletter a été historisée avec succès.');
    // const self = this;
    // const alert = await this.alertController.create({
    //   header: 'Newsletter historisée',
    //   cssClass: 'desktopDialog',
    //   message: 'Votre newsletter a été historisée avec succès.',
    //   buttons: [
    //     {
    //       text: 'Ok',
    //       handler: () => {
    //         self.modalController.dismiss();
    //       }
    //     }
    //   ]
    // });
    // await alert.present();
  }

  async saveAsTemplate() {
    const self = this;
    const alert = await this.alertController.create({
      header: 'Sauvegarder un nouveau template',
      cssClass: 'desktopDialog',
      message: 'Votre newsletter va être sauvegardée en tant que template. Êtes-vous sur de vouloir continuer ?',
      inputs : [
        {
          label : 'Nom du template',
          name : 'templateName',
          placeholder : 'Nom du template',
          id : 'templateName'
        }
      ],
      buttons: [
        {
          text: 'Oui',
          handler: (alertData) => {
            self.confirmSaveTemplate(alertData.templateName);
          }
        },
        {
          text: 'Non',
          handler: () => {
            self.modalController.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  confirmSaveTemplate(templateName) {
    const self = this;
    const clone = Object.assign({}, this.selectedNl);
    clone.name = templateName;
    getHtmlCss(function () {
      const htmlCss = (window as any).htmlcss;
      clone.html = htmlCss.html;
      clone.css = htmlCss.css;
      self.mailService.saveAsTemplate(clone).subscribe(value => {
        self.confirmSaveasTemplate();
      });
    });
  }

  async confirmSaveasTemplate() {
    this.snackBarService.showSuccessSnackbar('Template sauvegardé', 'Votre template a été sauvegardé avec succès.');
    // const self = this;
    // const alert = await this.alertController.create({
    //   header: 'Template sauvegardé',
    //   cssClass: 'desktopDialog',
    //   message: 'Votre template a été sauvegardé avec succès.',
    //   buttons: [
    //     {
    //       text: 'Ok',
    //       handler: () => {
    //         self.modalController.dismiss();
    //       }
    //     }
    //   ]
    // });
    // await alert.present();
  }
}
