import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {ArticlesService} from '../../../services/articles/articles.service';
import {MailsService} from '../../../services/mails/mails.service';
import {UserService} from '../../../services/user/user.service';
import {DomSanitizer} from "@angular/platform-browser";
import {Utils} from "../../../classes/Utils";
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";

@Component({
  selector: 'app-newsletter-preview-modal',
  templateUrl: './newsletter-preview-modal.component.html',
  styleUrls: ['./newsletter-preview-modal.component.scss'],
})
export class NewsletterPreviewModalComponent implements OnInit {
  public html: any;
  public email: any;

  constructor(private modalController: ModalController
      , private navParams: NavParams
      , private articlesService: ArticlesService
      , private snackBarService: SnackbarService
      , private mailService: MailsService
      , public userService: UserService
      , private sanitizer: DomSanitizer
      , private alertController: AlertController
      , private modalService: ModalService) {}

  ngOnInit() {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.mailService.nl.html);
    console.log(this.html);
    this.userService.getSessionDatas().subscribe(value => {
      this.email = value.customerDetails.email;
    });
  }

  public close(): void {
    this.modalController.dismiss();
  }

  sendNewsletter() {
    this.modalService.confirmModal('Envoyer la newsletter',
        'Voulez-vous vraiment envoyer la newsletter ?',
        args => {
          this.mailService.sendNewsletter(this.email).subscribe(value => {
            this.confirmNlSent();
          });
        });
  }

  async confirmNlSent() {
    this.snackBarService.showSuccessSnackbar('Newsletter envoyée', 'Votre newsletter a été envoyée avec succès.');
    this.modalController.dismiss();
    // const self = this;
    // const alert = await this.alertController.create({
    //   header: 'Newsletter envoyée',
    //   cssClass: 'desktopDialog',
    //   message: 'Votre newsletter a été envoyée avec succès.',
    //   buttons: [
    //     {
    //       text: 'Ok',
    //       handler: () => {
    //         self.modalController.dismiss();
    //       }
    //     }
    //   ]
    // });
    // await alert.present();
  }
}
